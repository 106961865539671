@import '@styles/variables/global-variables';

/**
 * Voices.com Additional Signup Page Styles
 *
 * $copyright Voices.com (c) 2006-2013
 */
 .form-login {
     .ui-form-box {
        min-height: 25px;
        a {
            padding-left: 0;
            color: $white;
            line-height: 25px;
            text-align: left;
            font-size: 16px;
            font-weight: 300;
            float: right;
        }
     }

 }

.signup-container { padding: 0 20px; margin-top: 10px; }
.signup-container img { max-height: 250px; margin-bottom: 25px;}
.signup-container-text {
	font-size: 20px;
	line-height: 29px;
	text-align: center;
	margin: 30px 20px 40px 20px;
}
.large { font-size: larger; }
.job-count { font-size: 32px !important; color: #444; }
.expandable-list-item-holder.account-type.talent { min-height: 430px; }
.expandable-list-item-holder.account-type {
    padding: 20px 15px 30px 15px;
    margin-top: 0;
    font-size: 18px;
    line-height: 1.5em;
}
.signup-info-detail {
    padding: 15px 0 25px 0;
    border-bottom: 1px #ccc solid;
}
.corner-ribbon {
    position: absolute; top: 0; right: -1px; display: block; width: 76px; height: 77px;
    background-repeat: no-repeat; background-size: 76px 77px;
}
.ribbon-most-popular { background-image: url('../images/structure/corner-ribbon-large-most-popular.png'); }
.ribbon-do-it-yourself { background-image: url('../images/structure/corner-ribbon-large-doit-yourself.png'); }
.ribbon-we-do-it { background-image: url('../images/structure/corner-ribbon-large-wedoit.png'); }

#sign-up-thankyou-list {
    margin: 10px 25px 20px 25px;
}
#sign-up-thankyou-list h3 {

    margin-top: 15px;
    text-align: center;
}
#sign-up-thankyou-list p {
    text-align: center;
    margin: 0 12% 10px 12%;
}
#sign-up-thankyou-list li {
    font-size: 18px;
    line-height: 1.5em;
}

/* From talent/plans_and_pricing */
#content .plans .header h2 {
    font-size: 24px !important;
    margin: 42px 0 10px 0 !important;
	line-height: 1.2em !important;
}
.plans .header h4 {
    margin: -6px 0 20px 0 !important;
}
.plans .header a {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    padding: 10px 18px;
}
#plans-key li {
    font-weight: 400;
}
#plans ul, #plans li {
    position: relative;
    min-height: 32px;
    line-height: 32px;
    list-style: none inside none;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 0;
    border-bottom: 1px #fff solid;
}
#plans ul:last-child, #plans .plan-tab li:last-child {
    border-bottom: none;
}
#plans > ul > li {
	float: left;
	width: 20%;
	padding-left: 10px;
    box-sizing: border-box;
}

#plans > ul > li:first-child { min-width: 40%; }

#plans ul ul li, #plans li.divider { height: 30px; line-height: 30px; text-align: center; border-bottom: 1px solid #ddd; }
#plans ul ul li:last-child { border-bottom: 0; }
#plans li.header {
    display: block;
    height: auto;
    margin: 0;
    padding: 10px;
    text-align: center;
    position: relative;
    border-bottom: 0;
}

#plans ul#plans-key li { line-height: 30px; padding-right: 10px; text-align: right; border-bottom-color: $white; }
/*#plans ul#plans-key li.header { height: 140px; }*/
#plans ul#plans-key li.section {
    /*font-family: lato;*/
    font-size: 21px;
    font-weight: 600;
}
#plans ul#plans-key li.section, #plans li.divider { margin-top: 10px; height: 40px; line-height: 40px; }
#plans .checked { color: #090; font-weight: bold; font-size: 20px; }

#plans ul#plans-key li.h_even { background-color: #eee; border-bottom-color: #eee; }

#premium { background-color: #e6f1e6; border-top-right-radius: 10px; border-bottom-right-radius: 10px; }
#plans ul ul#premium li { border-bottom-color: $white; }

.nav-tabs { border-bottom: 0; }
#plan-tabs.nav-tabs .tab-guest a,
#plan-tabs.nav-tabs .tab-premium_lite a,
#plan-tabs.nav-tabs .tab-premium a { border-top-left-radius: 10px; border-top-right-radius: 10px; border: 1px solid #ccc; border-bottom: 0; }

#plan-tabs.nav-tabs .tab-guest:not(active) a:hover,
#plan-tabs.nav-tabs .tab-premium_lite:not(active) a:hover,
#plan-tabs.nav-tabs .tab-premium:not(active) a:hover { background-color: #666; color: $white; }

#plan-tabs.nav-tabs .tab-guest.active a,
#plan-tabs.nav-tabs .tab-premium_lite.active a,
#plan-tabs.nav-tabs .tab-premium.active a { border: 0; border-bottom: 1px solid #000; }

#plan-tabs.nav-tabs .tab-guest.active a { background-color: #ededed; border-color: #ededed; }
#plan-tabs.nav-tabs .tab-premium_lite.active a { background-color: #D6E9F7; border-color: #D6E9F7; }
#plan-tabs.nav-tabs .tab-premium.active a { background-color: #dff0d8; border-color: #dff0d8; }
#plan-tabs > li {
    margin-bottom: -1px;
    border-bottom: 0 !important;
}
#plan-tabs > li > a > h3 {
    line-height: 21px;
    font-size: 21px !important;
	color: $blue1;
}
#plan-tabs > li > a {
    outline: none;
}
#plan-tabs > li > a > p {
    line-height: 18px;
    padding-top: 8px;
    font-size: 15px;
    font-weight: 400;
}
#plan-tabs.nav-tabs > li > a, #plan-tabs.nav-tabs > .nav > li > a:hover { border-radius: 0; }

.nav-tabs > .active > a, .nav-tabs > .active > a:hover { background-color: inherit; border-bottom: 0; color: inherit; }

#plans .tab-content .plan-tab li.section { font-weight: bold; margin-top: 10px; }
#plans .tab-content .plan-tab li span {
    display: inline-block;
    position: absolute;
    right: 20px;
}

.tab-pane { padding: 5px 10px; }
.tab-pane > p {
    text-align: center;
}
.tab-pane > p > a {
    margin-top: 23px;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
}
#tab-pane-guest { background-color: #ededed; border: 1px solid #ededed; }
#tab-pane-premium_lite { background-color: #D6E9F7; border: 1px solid #abd2ef; }
#tab-pane-premium { background-color: #dff0d8; border: 1px solid #659133; }
#tab-pane-guest, #tab-pane-premium, #tab-pane-premium_lite { border-top: 0; }

@media (min-width: 768px) and (max-width: 1000px) {
	#plans ul, #plans li {
		font-size: 17px !important;
	}
}

@media (max-width: 420px) {
	#plans ul, #plans li {
		font-size: 17px !important;
	}
}

@media (max-width: 400px) {
    #plan-tabs > li > a {
		padding: 4px 7px;
	}
	#plan-tabs > li > a > h3 {
		font-size: 18px !important;
	}
	#plan-tabs > li > a > p {
		font-size: 13px;
	}
}

/* Responsive controls */
@media (min-width: 980px) {
    .signup-container { padding: 0 60px; }
}
@media (max-width: 768px) {
    .signup-container { padding: 0 0; }
}

/* Retina image replacement */
@media only screen and (-webkit-min-device-pixel-ratio: 2),
       only screen and (-o-min-device-pixel-ratio: 2/1) {

    .ribbon-most-popular { background-image: url('../images/structure/corner-ribbon-large-most-popular-2x.png'); }
    .ribbon-do-it-yourself { background-image: url('../images/structure/corner-ribbon-large-doit-yourself-2x.png'); }
    .ribbon-we-do-it { background-image: url('../images/structure/corner-ribbon-large-wedoit-2x.png'); }
}


// sign up success page

.bkg-thumbsup{
	// height: 500px;
	text-align: center;
	background: url('#{$themePath}/thumbsup.gif') 50% 100% no-repeat;
	// background-size: 350px;
}

.blue-ribbon {
    justify-content: center;
    display: flex;
}

.col-container {
    display: table;
    width: 100%;
}
.col {
    display: table-cell;
    padding: 16px;
}

@media only screen and (max-width: 600px) {
    .col { 
        display: block;
        width: 100%;
    }
}

// title hidden display

h1 { display: none; }

// vertical allignment for column 

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
    text-align: left;
}

@media only screen and (max-width: 991px) {
    .vcenter {
        text-align: center;
    }
}

// blue ribbon style

.blue-ribbon p {
    text-align: center;
    vertical-align: top;
    margin: 0 0 25px;
    color: $white;
    font-size: 2.9rem;
    padding: 1rem;
    background-color: #1972b9;
}
@media (max-width: 767px) {
    .blue-ribbon p {
        font-size: 2rem;
    }
}

.guest-font {
    font-size: 1.4rem;
}

.alert-body-content a {
  text-decoration: none;
}

.Sign-up {
  width: 5.9rem;
  height: 2rem;
  margin: 0.2rem 0 0.2rem 0.2rem;
  font-family: Roboto;
  font-size: 1.6rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #0f7cc3;
}

.login-form-container, .forgot-password-container {
    margin-top: 5.5rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 57.8rem;
    padding: 5.6rem;
    border-radius: 8px;
    box-shadow: 0 5px 32px 0 rgba(25, 34, 43, 0.1);
    background-color: $white;
    @media (max-width: 767px) {
        margin-top: 3.3rem;
        width: 33rem;
        padding: 2.4rem 2.4rem 2.5rem 2.4rem;
        border-radius: 8px;
        box-shadow: 0 5px 32px 0 rgba(25, 34, 43, 0.1);
        background-color: $white;
    }
    @media (max-width: 991px) {
        margin-top: 4.6rem;
    }
}

.forgot-password-container{
    padding-top: 3rem;
}

.header-back-btn {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: 500;
    border-radius: 4px;
    transition: all .3s ease-in-out;
    text-decoration: none;
}

.header-back-btn, a.header-back-btn {
    color: $grey1;
}

h2{
    text-align: center;
    @media (max-width: 767px) {
       text-align: left;
    }
}

.login-sso-divider-line {
    margin-bottom: 1rem;
}

.customSocialSignIn {
	display: flex;
	align-items: center;
	border-radius: 4px;
	border: solid 1px #dadce0;
	white-space: nowrap;
	width: 250px;
	height: 4rem;
    padding: 1.2rem;
    transition: border-color .218s, background-color .218s;

	&:hover {
		cursor: pointer;
        border-color: #d2e3fc;
		background-color: #f8fafe;
	}

	.icon {
		width: 2.5rem;
		height: 1.8rem;
		background-size: 1.8rem;
		background-position: 50%;
		display: inline-block;
		vertical-align: middle;
	}

	.text {
		font-size: 1.4rem;
		font-weight: 500;
		line-height: 1.6rem;
		color: #3c4043;
		text-align: center;
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: center;
        font-family: sans-serif;
	}
}
